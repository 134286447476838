body {
  margin: 0;
  background-color: black;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header-text {
  text-align: center;
  color: whitesmoke;
  font-size: x-large;
  font-weight: 900;
  font-family: Arial Black;
  white-space: nowrap;
  margin: 1rem 0 1rem 0;
}
.header-sub-text {
  color: aliceblue;
  font-weight: 700;
  text-align: center;
  margin: 1rem 0 1rem 0;
}

.header-style {
  margin: 0;
  background-color: #49416d;
  line-height: 20px;
}

.text-style {
  width: 100%;
  font-size: 1.5rem;
  color: black;
  justify-content: center;
  line-height: 44px;
  font-weight: 600;
}

.sub-text-style {
  width: 100%;
  font-size: 1rem;
  color: black;
  line-height: 44px;
  font-weight: 600;
  text-align: left;
  display: flex;
}
